html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/**
  * Global Styles and Configurations
  */
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  font-size: 10px;
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: "Studio Pro", sans-serif;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

/**
         * Font Faces
         */
@font-face {
  font-family: "Studio Pro";
  src: url("/fonts/studio_pro_medium-webfont.woff2") format("woff2"),
    url("/fonts/studio_pro_medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
p {
  margin: 0;
}
